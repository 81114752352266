.root {
  display: flex;
  flex-direction: column;

  --required-content: 'Required';
  --form-padding-inline: var(--space-4);
}

.fieldset {
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
  border: 0;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-medium);
  line-height: var(--default-line-height);
  grid-area: controls;
}

.fieldset > .fieldset:last-child {
  border-bottom: 0;
}

.fieldset > .field:last-child > .fieldset:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.field {
  display: grid;
  grid-template-areas:
    'invalid label required'
    'invalid message message'
    'invalid controls controls'
    'invalid description description';
  align-items: baseline;
  grid-template-columns: auto auto 1fr;
  position: relative;
}

.field > * + * {
  margin-top: var(--space-2);
}

.field:has(> input[type='checkbox']) {
  align-items: baseline;
  grid-template-columns: auto auto auto 1fr;
  grid-template-areas:
    'invalid controls label required'
    'invalid message message message'
    'invalid description description description';
}

.field:has(> input[type='checkbox']) .control {
  position: relative;
  top: 3px;
}

.field:has(> input[type='checkbox']) .label {
  display: block;
  margin-top: 0;
  margin-left: var(--space-2);
}

.field:has(> input[type='checkbox']) :is(.message, .description) {
  margin-top: var(--space-2);
}

.field[hidden],
.field:has(> input[type='hidden']) {
  display: none;
}

.control,
.field > :global(.rt-TextFieldRoot),
.field > :global(.rt-SelectTrigger) {
  grid-area: controls;
}

.label {
  grid-area: label;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-medium);
  line-height: var(--default-line-height);
  display: flex;
  justify-content: space-between;
}

.field[data-required='true']::after {
  content: var(--required-content);
  color: var(--color-secondary);
  font-weight: var(--font-weight-medium);
  grid-area: required;
  justify-self: end;
}

.field[data-required='true'][data-invalid='true']::after {
  color: var(--accent-a9);
}

.field:before {
  content: '';
  width: 0;
  height: 100%;
  transition:
    width var(--duration-sh) var(--easing-emphasized),
    margin var(--duration-sh) var(--easing-emphasized);
}

.field[data-invalid='true']::before {
  width: var(--space-1);
  margin-right: var(--space-2);
  background-color: var(--accent-9);
  grid-area: invalid;
}

.message {
  grid-area: message;
  word-break: break-all;
  font-size: var(--font-size-1);
  color: var(--accent-a9);
}

.description {
  grid-area: description;
  color: var(--color-secondary);
  word-break: break-word;
}

.submit {
  --loader-open-delay: var(--duration-md);
  --loader-close-delay: 0s;
}

.stickyBox {
  position: sticky;
  top: -1px;
  z-index: 1;
  min-height: 0;
  border-radius: var(--radius-4);
}

.formMessage {
  grid-template-columns: auto minmax(0, 1fr);
  padding-block: var(--space-3);
  background-color: var(--accent-3);
}

.formMessage > .formMessageIcon {
  grid-column: 1;
}

.formMessage > .formMessageText {
  grid-column: 2;
}

.formMessageDismissButton {
  vertical-align: middle;
}

.formMessageDisclosureRoot {
  --content-animation-duration: var(--duration-md);
  --content-animation-easing: var(--easing-standard);
  --content-animation-delay: var(--duration-sh);
  border-radius: var(--radius-4);
}

.stickyBoxFloating .formMessageDisclosureRoot {
  box-shadow: var(--shadow-6);
}

.formMessageDisclosureRoot[data-state='open'] {
  --content-animation-delay: 0s;
  margin-bottom: var(--space-4);
}

.formMessageDisclosureRoot[data-state='closed'] {
  margin-bottom: 0;
}
